export const ChartColors = {
  green: "#9ff5e9",
  orange: "#f8d3d3",
  purple: "#ead6fb",
  blue: "#b6dff9",

  ates: "#ffca76",
  pending: "#fffa76",
  queued: "#ffeab0",
  accepted: "#BAFFC9",
  rejected: "#ff7676",
  undelivered: "#fcd5d6",
  blocked: "#ff7f76",
  expired: "#9ba3ba",
  unprocessed: "#aeaeae",
  processed: "#9acfef",
  delivered: "#8ee4d7",
  counter: "#6A8CAF",
  smscOdd: "#fcd5d6",
  smscEven: "#7188b3",
  smsSubmit: "#b6dff9",
  smsDelivered: "#ead6fb",
  msgProcessed: "#e8d7f9",

  singleMsg: "#0dcaf0",
  bulkMsg: "#a0f6e9",

  otpColor: "#1E2A5E",
  transaction: "#7C93C3",
  advertising: "#E2BFD9",
  country1: "#e9ecef", // Light pastel yellow
  country2: "#BAFFC9", // Light pastel green
  country3: "#BAE1FF", // Light pastel blue
  country4: "#E6A9EC", // Light pastel lavender
  country5: "#FFB2D9", // Light pastel rose
  sender1: "#FFC3A0", // Light pastel coral
  sender2: "#A0C3FF", // Light pastel sky blue
  sender3: "#C3FFA0", // Light pastel mint green
  sender4: "#D3A0FF", // Light pastel violet
  sender5: "#A0FFE1", // Light pastel aqua

  singleColor: "sage",
  broadNet: "teal",
  seller: "cyan",
  unitedArab: "beige",
  jordan: "maroon",
  samer: "brown",
};
