import React, { useState } from "react";
import { FaTrash } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { middleware, middlewareDelete } from "../../../redux/store";
import Modal from "../../Common/Modal";
import danger from "./../../../assets/img/danger.svg";
import {
  companySelector,
  companyUserList,
  deleteCompanyData,
} from "../CompanySlice";
import { useFetch } from "../../../hooks/useFetch";
import { isArray } from "../../../lib/utility";

export default function DeleteCompany({
  modal,
  toggleModal,
  companyData,
  getCompanyDataOnce,
  isLastData,
  setCompanyData,
}) {
  const [isDelete, setIsDelete] = useState(false);
  const [companyUserData, setCompanyUserData] = useState([]);

  const dispatch = useDispatch();
  const { isFetchingCompanyUserList } = useSelector(companySelector);

  const handleDelete = () => {
    if (companyData) {
      dispatch(deleteCompanyData({ udata: companyData.id })).then((action) =>
        middlewareDelete(
          action,
          "Delete Company",
          () => {
            setIsDelete(false);
            setCompanyData([]);
          },
          undefined,
          {
            modal: toggleModal,
            refresh: getCompanyDataOnce,
            isLast: isLastData,
          }
        )
      );
    }
  };
  const getCompanyUserList = () => {
    dispatch(companyUserList({ udata: companyData.id })).then((action) =>
      middleware(
        action,
        "Company User List",
        (action) => {
          if (isArray(action.payload.data))
            setCompanyUserData(action.payload.data);
        },
        undefined,
        {
          noToast: true,
        }
      )
    );
  };
  const handleCancelModal = () => {
    setIsDelete(false);
    toggleModal();
  };

  useFetch(() => {
    getCompanyUserList();
  });

  return (
    <>
      <Modal show={modal} close={toggleModal} justify="center" width={500}>
        <div className="modal-header">
          <h6 className="modal-title fs-22">
            <FaTrash className="align-text-bottom me-2 text-blue" />
            Delete Company
          </h6>
          <svg
            className="cursor-pointer"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleCancelModal}
          >
            <path
              d="M20 4L4 20M4 4L20 20"
              stroke="white"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="modal-body py-2 px-3 bg-white">
          <p className="fs-16 fw-600">
            Company Name:
            <span className="ps-2 text-blue">
              {companyData && companyData.companyName
                ? companyData.companyName
                : "N/A"}
            </span>
          </p>
          {isFetchingCompanyUserList ? (
            <>
              <div className="d-flex align-items-center py-3 px-3 shadow br-8">
                <img src={danger} alt="danger" loading="lazy" />
                <div className="ps-3">
                  <h6 className="text-danger fs-16 fw-600 mb-1">
                    Checking for company deletion
                  </h6>
                  <p className="fs-16 fw-400 text-dark m-0">
                    Please wait a while we are checking for Company Deletion.
                  </p>
                </div>
              </div>
            </>
          ) : companyUserData.length > 0 ? (
            <div className="d-flex align-items-center py-3 px-3 shadow br-8">
              <img src={danger} alt="danger" loading="lazy" />
              <div className="ps-3">
                <h6 className="text-danger fs-16 fw-600 mb-1">
                  Company deletion blocked{" "}
                </h6>
                <p className="fs-16 fw-400 text-dark m-0">
                  This company has associated users. Remove or transfer these
                  users to proceed with deletion
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center my-3">
                <input
                  className="form-check-input float-none me-2"
                  type="checkbox"
                  id="deleteCheck"
                  checked={isDelete}
                  onChange={(e) => setIsDelete(e.target.checked)}
                />
                <label
                  className="form-check-label fs-18 fw-600 text-dark"
                  htmlFor="deleteCheck"
                >
                  Please check this checkbox in order to delete
                  <span className="text-danger">*</span>
                </label>
              </div>
              <div className="d-flex align-items-center py-3 px-3 shadow br-8">
                <img src={danger} alt="danger" loading="lazy" />
                <div className="ps-3">
                  <h6 className="text-danger fs-16 fw-600 mb-1">
                    Are you sure you want to delete this Company ?{" "}
                  </h6>
                  <p className="fs-16 fw-400 text-dark m-0">
                    Once deleted, company associate details cannot be recovered.
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="modal-footer d-block bg-white">
          <div className="row g-3">
            {isFetchingCompanyUserList || companyUserData.length !== 0 ? (
              <div className="mt-0">
                <button
                  className="bg-primary-red text-white btn w-100 fs-16 fw-500 br-12 px-4 py-2 border_lightgray_1"
                  onClick={handleCancelModal}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <>
                <div className="col-6 mt-0">
                  <button
                    className="btn fs-16 fw-500 br-12 w-100 px-4 py-2 border_lightgray_1 me-2"
                    onClick={handleDelete}
                    disabled={!isDelete}
                  >
                    Confirm
                  </button>
                </div>
                <div className="col-6 mt-0">
                  <button
                    className="bg-primary-red text-white btn w-100 fs-16 fw-500 br-12 px-4 py-2 border_lightgray_1"
                    onClick={handleCancelModal}
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
