import React, { useState } from "react";
import dangerAlert from "../../assets/img/danger-alert.svg";
import WalletNotify from "../../assets/img/wallet-notify.svg";
import CloseButton from "../../components/Common/CloseButton";
import Modal from "../../components/Common/Modal";
import BalanceUser from "../../components/User/UserModal/BalanceUser";
import { checkForWalletMin, getLocalUser } from "../../lib/utility";

export default function AddBalanceRequestModal({
  modal,
  toggleModal,
  profileData,
  deleteNotificationOnce,
  addBalanceId,
}) {
  const [balanceUserModal, setBalanceUserModal] = useState(false);

  const addBalanceLater = () => {
    deleteNotificationOnce(addBalanceId?.id);
    toggleModal();
  };

  const [id, walletFlag, requiredAmount, currentAmount] =
    addBalanceId?.referenceId.split(" ");

  return (
    <>
      <Modal show={modal} close={toggleModal} justify="center">
        <div className="modal-header">
          <h6 className="modal-title d-flex align-items-center gap-2 fs-22">
            <img src={dangerAlert} alt="" loading="lazy" />
            Add Balance Request
          </h6>
          <CloseButton theme={"dark"} onClick={addBalanceLater} />
        </div>
        <div className="modal-body bg-white d-flex justify-content-center align-items-center gap-2 p-4">
          <img src={WalletNotify} alt="" style={{ width: 40 }} loading="lazy" />
          <div>
            <p
              style={{
                marginBottom: "5px",
                fontSize: "22px",
                lineHeight: "1",
                fontWeight: "500",
              }}
            >
              Dear {profileData.userName},<br />
              You have received a new request for wallet recharge from
            </p>
            <p style={{ marginBottom: "0px" }}>
              User: - {getLocalUser("map")?.[id]}({id}) <br />
              Required {checkForWalletMin(walletFlag) ? "Wallet" : "Credit"}{" "}
              Amount: {requiredAmount} <br />
              Current {checkForWalletMin(walletFlag)
                ? "Wallet"
                : "Credit"} Amount: {currentAmount}
            </p>
          </div>
        </div>
        <div className="modal-footer bg-white">
          <div className="row g-3 w-100">
            <div className="col-6 mt-0">
              <button
                onClick={addBalanceLater}
                className="btn fs-16 fw-500 br-12 w-100 px-4 py-2 border_lightgray_1 me-2"
              >
                Later
              </button>
            </div>
            <div className="col-6 mt-0">
              <button
                type="button"
                className="cbtn text-white btn w-100 fs-16 fw-500 br-12 px-4 py-2 border_lightgray_1"
                onClick={() => {
                  setBalanceUserModal(true);
                }}
              >
                Add Balance
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {balanceUserModal && (
        <BalanceUser
          closeModal={() => {
            setBalanceUserModal(false);
            toggleModal();
            deleteNotificationOnce(addBalanceId?.id);
          }}
          modal={balanceUserModal}
          userData={{ id: +id, walletFlag }}
          addBalance
        />
      )}
    </>
  );
}
