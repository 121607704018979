import React, { useCallback, useState } from "react";
import Modal from "./Modal";
import { FaUsers } from "react-icons/fa";
import CloseButton from "./CloseButton";
import FormEnter from "./FormEnter";
import { CiSearch } from "react-icons/ci";
import LoaderComponent from "./LoaderComponent";
import DataTable from "react-data-table-component";
import { useFetch } from "../../hooks/useFetch";
import { userListGet, userSelector } from "../User/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { tableCustomStyles } from "../../lib/utils";
import { middleware } from "../../redux/store";
import { authSelector } from "../Auth/AuthSlice";
import { hasRoles, showToast } from "../../lib/utility";
import { useLocation } from "react-router-dom";

const UserFilter = ({
  modalClose,
  handleResetClick,
  onApplyFilter,
  onNextClick,
  userModalParams,
}) => {
  const [searchVal, setSearchVal] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(usersList);

  const { user } = useSelector(authSelector);
  const { pathname } = useLocation();
  const {
    onlyUserSelect,
    canSelectUsers,
    multipleUsers,
    ifId,
    allSelectedText,
    ifNotAll,
    allSelected,
  } = userModalParams;

  const isSupers =
    canSelectUsers === undefined
      ? hasRoles("admin,superadmin,system,seller,manager", user) &&
        window.location.pathname.includes("reports")
      : canSelectUsers;
  const isSellerOrManager = hasRoles("seller,manager", user);
  const { isFetchingUserList } = useSelector(userSelector);
  const dispatch = useDispatch();
  const data = useCallback(filteredUsers, [filteredUsers, usersList]);
  const columns = [
    {
      name: "Username",
      selector: (row) =>
        isSellerOrManager ? row.userEntry.systemId : row.systemId,
      sortable: true,
    },
    {
      name: "Master Id",
      selector: (row) =>
        isSellerOrManager ? row.userEntry.masterId : row.masterId,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) =>
        isSellerOrManager ? row.userEntry.currency : row.currency,
      sortable: true,
    },
  ];

  const [selectUsers, setSelectUsers] = useState({
    allowed: isSupers,
    userIds: "",
    next: false,
    allSelected: false,
  });

  const handleSelectedUserChange = ({
    allSelected,
    selectedCount,
    selectedRows,
  }) => {
    if (!multipleUsers && !allSelected && selectedCount > 1) {
      showToast("Can only select 1 user", "warning");
    }

    const selectedIds = selectedRows
      .map((sr) =>
        isSellerOrManager
          ? sr.userEntry[ifId ? "id" : "systemId"]
          : sr[ifId ? "id" : "systemId"]
      )
      .join(",");

    setSelectUsers((prev) => ({
      ...prev,
      userIds: allSelected
        ? ifNotAll
          ? selectedIds
          : allSelectedText
        : selectedIds,
      allSelected,
    }));
  };
  const handleNextClick = () => {
    if (onlyUserSelect) {
      onApplyFilter({
        clientId: selectUsers.userIds,
        allSelected: usersList.length === selectUsers.userIds.split(",").length,
      });
      modalClose();
      return;
    }
    if (selectUsers.userIds !== "") {
      setSelectUsers((prev) => ({ ...prev, next: true }));
      onNextClick({ ...selectUsers, next: true });
    } else {
      showToast("Select at least one User.", "warning");
    }
  };
  const getUsersList = () => {
    dispatch(userListGet("editUser")).then((action) =>
      middleware(action, "User List", (action) => {
        const userMap = {};
        action.payload[isSellerOrManager ? "usernameExt" : "usernames"].forEach(
          (userTemp) => {
            userMap[userTemp.id] = userTemp.systemId;
          }
        );
        setUsersList(
          action.payload[isSellerOrManager ? "usernameExt" : "usernames"]
        );
        setFilteredUsers(
          action.payload[isSellerOrManager ? "usernameExt" : "usernames"]
        );
      })
    );
  };
  const handleSearch = (e) => {
    let searchVal = e.target.value;
    if (searchVal && searchVal !== "" && e.type !== "blur") {
      setFilteredUsers(
        usersList.filter(
          (user) =>
            user?.systemId?.toLowerCase().includes(searchVal.toLowerCase()) ||
            (isSellerOrManager &&
              user.userEntry.systemId
                .toLowerCase()
                .includes(searchVal.toLowerCase()))
        )
      );
    } else if (searchVal == "") setFilteredUsers(usersList);
    setSearchVal(searchVal);
  };

  useFetch(() => {
    if (isSupers) getUsersList();
  });

  return (
    <>
      {selectUsers.allowed && !selectUsers.next && (
        <Modal show={true} close={modalClose} justify="center" width={850}>
          <div className="modal-header">
            <h6 className="modal-title d-flex align-items-center gap-2 fs-22">
              <FaUsers />
              Select Users
            </h6>
            <CloseButton theme={"dark"} onClick={modalClose} />
          </div>
          <div className="modal-body bg-white">
            <div className="d-flex justify-content-end mb-3">
              <FormEnter onSubmit={handleSearch}>
                <div className="input-group border br-8 bg-transparent text-white">
                  <input
                    type="text"
                    className="form-control input-style border-0"
                    placeholder="Search..."
                    value={searchVal}
                    onChange={handleSearch}
                  />
                  <button className="btn" type="submit">
                    <CiSearch className="fs-25" strokeWidth={1} />
                  </button>
                </div>
              </FormEnter>
            </div>
            {isFetchingUserList && <LoaderComponent />}
            {!isFetchingUserList && (
              <DataTable
                customStyles={tableCustomStyles({
                  height: "30vh",
                  minHeight: "max-content",
                  tableStyle: {
                    border: "1px solid #ccc",
                    borderRadius: 8,
                  },
                })}
                columns={columns}
                data={data}
                highlightOnHover
                fixedHeader
                selectableRows
                selectableRowsSingle={!multipleUsers && !allSelected}
                onSelectedRowsChange={handleSelectedUserChange}
                noDataComponent={<div className="p-4">No data available</div>}
              />
            )}
          </div>
          <div className="modal-footer bg-white">
            <div className="row g-3 w-100">
              <div className="col-6 mt-0">
                <button
                  type="button"
                  className="btn fs-16 fw-500 br-12 w-100 px-4 py-2 border_lightgray_1 me-2"
                  onClick={handleResetClick}
                >
                  Reset
                </button>
              </div>
              <div className="col-6 mt-0">
                <button
                  type="button"
                  className="cbtn text-white btn w-100 fs-16 fw-500 br-12 px-4 py-2 border_lightgray_1"
                  onClick={handleNextClick}
                  disabled={
                    !multipleUsers &&
                    (selectUsers.userIds === "" ||
                      selectUsers.userIds.split(",").length !== 1)
                  }
                >
                  {onlyUserSelect ? "Submit" : "Next"}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default UserFilter;
