import React, { useState } from "react";
import { FiFlag } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useFetch } from "../../hooks/useFetch";
import Container from "../../Layouts/Container";
import { middleware, middlewareUpdate } from "../../redux/store";
import { getLast10thDate } from "../../Wrapper/CommonFun";
import CommonHeader from "../Common/CommonHeader";
import DateFilterModal from "../Common/DateFilterModal";
import TableWithHeader from "../Common/TableWithHeader";
import icPlus from "./../../assets/img/plusIcon.svg";
import AddCompany from "./CompanyModals/AddCompany";
import BalanceCompanyUser from "./CompanyModals/BalanceCompanyUser";
import DeleteCompany from "./CompanyModals/DeleteCompany";
import EditCompany from "./CompanyModals/EditCompany";
import {
  clearStateCompany,
  companySelector,
  getCompanyData,
  updateFlag,
} from "./CompanySlice";
import CTIcon from "../../assets/icons/transcomp.svg";
import CompanyTable from "./CompanyTable";
import { useNavigate } from "react-router-dom";

export default function Company() {
  const navigate = useNavigate();
  const { isFetchingCompanyData } = useSelector(companySelector);
  const { startDate, endDate } = getLast10thDate();

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterData, setFilterData] = useState({
    startDate: endDate,
    endDate: startDate,
  });
  const [showCreateCompanyModal, setShowCreateCompanyModal] = useState(false);
  const [editModal, setEditModal] = useState({
    show: false,
    details: undefined,
  });
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    details: undefined,
  });
  const [searchVal, setSearchVal] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [addBalance, setAddBalance] = useState({
    show: false,
    details: undefined,
  });

  const dispatch = useDispatch();

  const getCompanyDataOnce = (str) => {
    dispatch(getCompanyData(str)).then((action) =>
      middleware(action, "Company list", () => {
        setCompanyData(action.payload.data);
        clearStateCompany();
      })
    );
  };
  const handleApplyFilter = ({ startDate, endDate }, search) => {
    let str = `?startDate=${startDate.split(" ")[0]}&endDate=${
      endDate.split(" ")[0]
    }${search ? `&search=${search}` : ""}`;
    setFilterData({ startDate, endDate });
    getCompanyDataOnce(str);
  };
  const handleResetFilters = () => {
    getCompanyDataOnce();
  };
  const handleSearch = (e) => {
    if (searchVal && searchVal !== "" && e.type !== "blur") {
      handleApplyFilter(filterData, searchVal);
    } else if (e.type === "blur" && searchVal == "") getCompanyDataOnce();
  };
  const handleOpenModal = () => {
    setShowCreateCompanyModal(true);
  };
  const deleteButtonHandler = (show, details) => {
    setDeleteModal({ show, details });
  };
  const addBalanceHandler = (show, details) => {
    setAddBalance({ show, details });
  };
  const editButtonHandler = (show, details) => {
    setEditModal({ show, details });
  };
  const handleFlag = () => {
    dispatch(updateFlag()).then((action) =>
      middlewareUpdate(action, "Update Flag", () => {}, undefined, {
        refresh: getCompanyDataOnce,
      })
    );
  };

  useFetch(() => {
    getCompanyDataOnce();
  });

  return (
    <Container>
      <CommonHeader name={"Company"}>
        <>
          <button
            className="btn userConf d-flex align-items-center"
            data-tooltip="Transaction History"
            data-tooltip-location="bottom"
            onClick={() => {
              navigate("/company/transaction-history");
            }}
          >
            <img
              src={CTIcon}
              className="card-img-top"
              alt="..."
              loading="lazy"
              style={{ height: 18 }}
            />
          </button>
          <button
            data-tooltip="Flag"
            data-tooltip-location="bottom"
            onClick={handleFlag}
            className="userConf"
          >
            <FiFlag fill="#2D8A39CC" color="#2D8A39CC" />
          </button>
          <button
            className="userConf cbtn d-flex align-items-center"
            data-tooltip="Add Company"
            data-tooltip-location="bottom"
            onClick={handleOpenModal}
          >
            <img
              src={icPlus}
              className="card-img-top"
              alt="..."
              loading="lazy"
              style={{ height: 18 }}
            />
          </button>
        </>
      </CommonHeader>
      <TableWithHeader
        name={"Company List"}
        setShowFilterModal={setShowFilterModal}
        search={{
          searchVal,
          setSearchVal,
          handleSearch,
          saveSearchKey: "company",
        }}
        serverSearch
      >
        <CompanyTable
          companyTableData={companyData}
          isFetching={isFetchingCompanyData}
          editButtonHandler={editButtonHandler}
          deleteButtonHandler={deleteButtonHandler}
          addBalanceHandler={addBalanceHandler}
        />
      </TableWithHeader>
      {showCreateCompanyModal && (
        <AddCompany
          modal={showCreateCompanyModal}
          toggleModal={() => setShowCreateCompanyModal(false)}
          getCompanyDataOnce={getCompanyDataOnce}
        />
      )}
      {showFilterModal && (
        <DateFilterModal
          modal={showFilterModal}
          closeModal={() => setShowFilterModal(false)}
          canSelectUsers={false}
          onApplyFilter={handleApplyFilter}
          onResetFilter={handleResetFilters}
        />
      )}
      {editModal.show && editModal.details && (
        <EditCompany
          modal={editModal.show}
          toggleModal={() => setEditModal({ show: false })}
          companyData={editModal.details}
          getCompanyDataOnce={getCompanyDataOnce}
        />
      )}
      {deleteModal.show && deleteModal.details && (
        <DeleteCompany
          modal={deleteModal.show}
          toggleModal={() => setDeleteModal(false)}
          companyData={deleteModal.details}
          isLastData={companyData.length === 1}
          getCompanyDataOnce={getCompanyDataOnce}
          setCompanyData={setCompanyData}
        />
      )}
      {addBalance.show && addBalance.details && (
        <BalanceCompanyUser
          modal={addBalance}
          closeModal={() => setAddBalance(false)}
          companyData={addBalance.details}
          getCompanyDataOnce={getCompanyDataOnce}
        />
      )}
    </Container>
  );
}
