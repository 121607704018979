
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.scss";
import { persistor, store } from "./redux/store";
import * as Sentry from "@sentry/react";
import { getItem } from "./lib/utility";

// Custom Fallback UI for Errors
const Fallback = ({ error, resetErrorBoundary }) => (
  <div style={{ backgroundColor: "yellow", padding: "10px" }}>
    <h2 style={{ color: "teal" }}>Something went wrong</h2>
    <p style={{ color: "red" }}>Error message: {error.message}</p>
    <button onClick={resetErrorBoundary}>Try again</button>
  </div>
);

// Get user profile data
const user = (globalThis.profileData = getItem("profileData", true) || null);

// Initialize Sentry
Sentry.init({
  dsn: "https://97ffc138f2a69f54a0624cc4b9cc6086@o4508438638886912.ingest.de.sentry.io/4508442313752656",
  environment: "production", // Set environment dynamically if needed

  beforeSend(event, hint) {
    // Filter out specific errors by message
    if (hint?.originalException?.message === "Network Error") {
      return null; // Prevents sending events with the message "Network Error"
    }

    // Filter out TypeErrors
    if (hint?.originalException instanceof TypeError) {
      return null; // Prevents sending TypeErrors
    }
    // Filter out ReferenceError
    if (hint?.originalException instanceof ReferenceError) {
      return null; // Prevents sending TypeErrors
    }

    // Filter out events from development environment
    if (event.environment === "development") {
      return null; // Prevents sending events from development
    }

    // Filter out warnings
    if (event.level === "warning") {
      return null; // Prevents sending warning level events
    }

    // Customize event data before sending
    event.tags = {
      ...event.tags,
      customTag: "CustomTagValue", // Add custom tag to event
    };

    event.extra = {
      ...event.extra,
      debugInfo: "Additional debug context", // Add extra debug information
    };

    event.user = {
      ...event.user,
      id: user?.masterID || "unknown", // Set user ID dynamically
      email: user?.email || "unknown@example.com", // Set user email
      username: user?.userName || "Guest", // Set username
    };

    // Filter events based on custom logic (e.g., specific tags)
    if (event.tags?.feature === "login") {
      return null; // Prevents sending events related to the login feature
    }

    // Allow event to be sent
    return event;
  },
});

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer style={{ zIndex: 100000 }} />
        <Sentry.ErrorBoundary fallback={<Fallback />}>
          <App />
        </Sentry.ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
