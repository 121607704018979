import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import calenderIcon from "../../assets/img/calenderIcon.svg";
import { useFetch } from "../../hooks/useFetch";
import {
  addTimeInDates,
  convertGmtToOffest,
  getBrowserGMTOffset,
  hasRoles,
  kindOf,
  showToast,
} from "../../lib/utility";
import { getDatesFromDay } from "../../lib/utils";
import { middleware } from "../../redux/store";
import { getLast10thDate } from "../../Wrapper/CommonFun";
import timeOptions from "../../Wrapper/GmtTimeData";
import { authSelector } from "../Auth/AuthSlice";
import {
  getCountryList,
  getOperatorByCountryList,
} from "../Report/ReportSlice";
import CloseButton from "../Common/CloseButton";
import FormEnter from "../Common/FormEnter";
import Modal from "../Common/Modal";
import Select from "../Common/Select";
import UserFilter from "../Common/UserFilter";

const CompanyTxnFilter = ({
  modal,
  closeModal,
  onApplyFilter,
  onResetFilter,
  canSelectUsers,
  countryOperators = false,
  horizontal = true,
  multipleUsers = false,
  ifNotAll = false,
  ifId = false,
  allSelectedText = "ALL",
  onlyUserSelect = false,
  notDate = false,
  saveFilterKey,
}) => {
  let { startDate, endDate } = getLast10thDate();
  const initialValues = {
    gmtOffset: getBrowserGMTOffset(),
    startDate: endDate.split(" ")[0],
    endDate: startDate.split(" ")[0],
    country: "",
    operator: "",
    status: "SUCCESS",
    type: "CREDIT",
  };

  const [filterData, setFilterData] = useState(initialValues);
  const [countryList, setCountryList] = useState([]);
  const [operatorList, setOperatorList] = useState([]);
  const [daySelector, setDaySelector] = useState("10");

  const { user } = useSelector(authSelector);
  const dispatch = useDispatch();
  const isSupers =
    canSelectUsers === undefined
      ? hasRoles("admin,superadmin,system,seller,manager", user)
      : canSelectUsers &&
        hasRoles("admin,superadmin,system,seller,manager", user);

  const [selectUsers, setSelectUsers] = useState({
    allowed: isSupers,
    userIds: "",
    next: false,
  });

  const handleFilterDataChange = (e) => {
    const { value, id } = e.target;

    setFilterData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  const getFilterObj = () =>
    addTimeInDates(
      {
        startDate: filterData.startDate,
        endDate: filterData.endDate,
        country: filterData.country,
        operator: filterData.operator,
        statusType: filterData.status,
        types: filterData.type,
      },
      convertGmtToOffest(filterData.gmtOffset)
    );
  const handleApplyClick = () => {
    if (
      (notDate || (filterData.startDate !== "" && filterData.endDate !== "")) &&
      (countryOperators
        ? filterData.country !== ""
          ? filterData.operator !== ""
          : filterData.operator === ""
        : true)
    ) {
      kindOf(onApplyFilter, "function") && onApplyFilter(getFilterObj());
      modalClose();
    } else {
      showToast(
        "Please select Start and End date" +
          (countryOperators &&
            filterData.country !== "" &&
            filterData.operator == "" &&
            " and Operator also"),
        "warning"
      );
    }
  };
  const handleResetClick = () => {
    setFilterData(initialValues);
    setSelectUsers({
      allowed: isSupers,
      userIds: "",
      next: false,
    });
    kindOf(onResetFilter, "function") && onResetFilter();
    modalClose();
  };
  const handleFilterSubmit = () => {
    handleApplyClick();
  };
  const handleSaveFilter = () => {
    handleApplyClick();
  };
  const modalClose = () => {
    setSelectUsers({
      allowed: isSupers,
      userIds: "",
      next: false,
    });
    closeModal();
  };
  const handleCountryChange = (value) => {
    setFilterData((prev) => ({ ...prev, country: value, operator: "" }));
    if (value !== "")
      getOperatorsByCountries(
        countryList.find((country) => country.mcc === value)?.country
      );
  };
  const getCountries = () => {
    dispatch(getCountryList()).then((action) =>
      middleware(
        action,
        "Countries",
        () => {
          if (Array.isArray(action.payload.data))
            setCountryList(action.payload.data);
        },
        undefined,
        {
          modal: closeModal,
        }
      )
    );
  };
  const getOperatorsByCountries = (id) => {
    dispatch(getOperatorByCountryList(id)).then((action) =>
      middleware(
        action,
        "Operators By Countries",
        () => {
          if (Array.isArray(action.payload.data))
            setOperatorList(action.payload.data);
        },
        undefined,
        {
          modal: closeModal,
        }
      )
    );
  };
  const handleDayChange = (e) => {
    let value = e.target.value;

    let { startDate, endDate } = getDatesFromDay(value);
    setFilterData((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));
    setDaySelector(value);
  };

  useFetch(() => {
    if (countryOperators) getCountries();
  });

  return (
    <>
      <UserFilter
        modalClose={modalClose}
        handleResetClick={handleResetClick}
        onNextClick={(value) => setSelectUsers(value)}
        onApplyFilter={onApplyFilter}
        userModalParams={{
          onlyUserSelect,
          canSelectUsers,
          multipleUsers,
          ifId,
          allSelectedText,
          ifNotAll,
        }}
      />
      {(!selectUsers.allowed ||
        (selectUsers.allowed &&
          selectUsers.userIds !== "" &&
          selectUsers.next)) && (
        <Modal show={modal} close={modalClose} justify="center">
          <div className="modal-header">
            <div className="d-flex gap-2 align-items-center">
              <h6 className="modal-title fs-22">
                <img src={calenderIcon} loading="lazy" />
                Filter
              </h6>
              {!notDate && (
                <div className="dropdown" style={{ scale: "0.9" }}>
                  <select
                    className="form-select"
                    value={daySelector}
                    onChange={handleDayChange}
                    style={{ borderRadius: 8 }}
                  >
                    <option value="0">Today</option>
                    <option value="1">Yesterday</option>
                    <option value="3">Last 3 Days</option>
                    <option value="7">Last 7 Days</option>
                    <option value="10">Last 10 Days</option>
                    <option value="15">Last 15 Days</option>
                    <option value="30">Last 30 Days</option>
                  </select>
                </div>
              )}
            </div>
            <CloseButton theme={"dark"} onClick={modalClose} height={18} />
          </div>
          <FormEnter onSubmit={handleFilterSubmit}>
            <div className="modal-body py-2 px-3 bg-white">
              <>
                <div className="row filter g-3">
                  {!notDate && (
                    <>
                      <div className="col-md-12">
                        <label className="form-label">GMT</label>
                        <select
                          className="form-select"
                          id="gmtOffset"
                          value={filterData.gmtOffset}
                          onChange={handleFilterDataChange}
                        >
                          {timeOptions.map((gmt) => (
                            <option value={gmt.value} key={gmt.value}>
                              {gmt.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label">From date</label>
                        <input
                          type="date"
                          className="form-control text-uppercase"
                          id="startDate"
                          value={filterData.startDate}
                          onChange={handleFilterDataChange}
                          required
                          max={new Date().toISOString().split("T")[0]}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label">To date</label>
                        <input
                          type="date"
                          className="form-control text-uppercase"
                          id="endDate"
                          value={filterData.endDate}
                          onChange={handleFilterDataChange}
                          required
                          max={new Date().toISOString().split("T")[0]}
                          {...(filterData.startDate
                            ? {
                                min: new Date(filterData.startDate)
                                  .toISOString()
                                  .split("T")[0],
                              }
                            : {})}
                        />
                      </div>
                    </>
                  )}
                  <div className="col-12 col-md-6">
                    <label className="form-label">Status</label>
                    <select
                      className="form-select"
                      id="status"
                      value={filterData.status}
                      onChange={handleFilterDataChange}
                    >
                      <option value="SUCCESS">Success</option>
                      <option value="REJECTED">Rejected</option>
                      <option value="PENDING">Pending</option>
                    </select>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="form-label">Type</label>
                    <select
                      className="form-select"
                      id="type"
                      value={filterData.type}
                      onChange={handleFilterDataChange}
                    >
                      <option value="CREDIT">Credit</option>
                      <option value="DEBIT">Debit</option>
                    </select>
                  </div>
                </div>
              </>
            </div>
            <div className="modal-footer d-block bg-white">
              <div className="row g-3">
                <div className="col-6 mt-0">
                  <button
                    type="button"
                    className="btn fs-16 fw-500 br-12 w-100 px-4 py-2 border_lightgray_1 me-2"
                    onClick={handleResetClick}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 mt-0">
                  <button
                    type="submit"
                    className="cbtn text-white btn w-100 fs-16 fw-500 br-12 px-4 py-2 border_lightgray_1"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </FormEnter>
        </Modal>
      )}
    </>
  );
};

export default CompanyTxnFilter;
