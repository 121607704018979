import React, { Fragment, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FaCheck, FaFloppyDisk } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import filterIcon from "../../assets/img/filter.svg";
import useModal from "../../hooks/useModal";
import { getSearch, isArray, saveSearch, showToast } from "../../lib/utility";
import { middleware } from "../../redux/store";
import { saveFilter } from "../Report/ReportSlice";
import FormEnter from "./FormEnter";
import SaveFilter from "./SaveFilter";

const TableWithHeader = ({
  name,
  setShowFilterModal,
  search,
  serverSearch = false,
  customChildren,
  charts,
  customChildrenPlacement = "first",
  showSavedFilter,
  handleSavedFilterClick,
  children,
}) => {
  let showSearch = !!search;
  search = search ?? {};
  let {
    searchVal,
    setSearchVal,
    handleSearch,
    saveSearchKey,
    saveFilterKey,
    filterData,
    refreshFilter,
  } = search;

  const [filterMobActive, setFilterMobActive] = useState(false);
  const [selectedSavedFilter, setSelectedSavedFilter] = useState("");
  const [savedSearch, setSavedSearch] = useState(false);

  const inputRef = useRef();
  const dispatch = useDispatch();
  const { clickRef, contRef, showModal, handleOpen, handleClose } = useModal();
  const { x, y, right, height, width } =
    clickRef.current?.getBoundingClientRect() ?? {};

  const handleFilterActive = (e) => {
    if (!filterMobActive) {
      e?.preventDefault();
      inputRef.current.focus();
      setFilterMobActive(true);
    }

    if (searchVal && searchVal !== "") {
      handleSearch(e);
    }
  };
  const handleBlur = (e) => {
    setFilterMobActive(false);
    if (serverSearch) handleSearch(e);
  };
  const handleSaveSearch = () => {
    if (!searchVal || searchVal === "") {
      showToast("Search Value is empty", "warning");
      return;
    }
    saveSearch(saveSearchKey, searchVal);
    setSavedSearch(true);
    setTimeout(() => {
      setSavedSearch(false);
    }, 2000);
  };
  const handleSaveClick = (saveText) => {
    if (saveText === "") {
      showToast("Filter name can not be empty", "warning");
      return;
    }

    dispatch(
      saveFilter({
        recentFilterName: saveText,
        purpose: saveFilterKey,
        createdOn: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        specialAttributes: { ...filterData, searchValue: searchVal },
      })
    ).then((action) =>
      middleware(
        action,
        "Save Filter",
        () => {
          refreshFilter && refreshFilter();
          handleClose();
          setSavedSearch(true);
          setTimeout(() => {
            setSavedSearch(false);
          }, 2000);
        },
        undefined,
        {
          modal: handleClose,
        }
      )
    );
  };
  const handleSavedFilterChange = (e) => {
    let id = e.target.value;
    let specialAttributes = showSavedFilter.find(
      (sf) => sf.id === id
    )?.specialAttributes;

    setSelectedSavedFilter(id);
    specialAttributes &&
      handleSavedFilterClick &&
      handleSavedFilterClick(specialAttributes);
  };

  return (
    <>
      {charts && (
        <div className="charts | row mb-4 g-3">
          {charts.map((chart, index) => (
            <div className="col-12 col-md-6" key={index}>
              {chart}
            </div>
          ))}
        </div>
      )}
      <div
        className="commonTableHeader d-flex"
        style={{ flexDirection: "column" }}
      >
        <div
          className={
            "d-flex align-items-center flex-wrap commonTable" +
            (name ? " justify-content-between" : " noName justify-content-end")
          }
        >
          {name && <p className="name">{name}</p>}
          <div className="d-flex align-items-center" style={{ gap: 8 }}>
            {customChildrenPlacement === "first" && (
              <div
                className="custom | d-flex align-items-center"
                style={{
                  gap: 8,
                  width: filterMobActive ? 0 : "max-content",
                }}
              >
                {isArray(customChildren)
                  ? customChildren.map((child, index) => (
                      <Fragment key={index}>{child}</Fragment>
                    ))
                  : customChildren}
              </div>
            )}
            {setShowFilterModal && (
              <button
                className="filterBtn"
                onClick={() => setShowFilterModal(true)}
              >
                <img src={filterIcon} alt="" loading="lazy" />
                <span>Filter</span>
              </button>
            )}
            {showSavedFilter && showSavedFilter.length > 0 && (
              <div className="dropdown savedFilter">
                <select
                  className="form-select text-white"
                  value={selectedSavedFilter}
                  onChange={handleSavedFilterChange}
                  style={{ borderRadius: 8 }}
                >
                  <option value="">-Saved Filters-</option>
                  {showSavedFilter &&
                    showSavedFilter.map((f, index) => (
                      <option key={index} value={f.id}>
                        {f.recentFilterName}
                      </option>
                    ))}
                </select>
              </div>
            )}
            {showSearch && (
              <>
                <FormEnter
                  {...(serverSearch ? { onSubmit: handleSearch } : {})}
                >
                  <div
                    className={
                      "searchInput" +
                      (filterMobActive || searchVal !== "" ? " active" : "")
                    }
                  >
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchVal}
                      onChange={(e) =>
                        setSearchVal
                          ? setSearchVal(e.target.value)
                          : handleSearch(e)
                      }
                      onBlur={handleBlur}
                      list={saveSearchKey}
                      ref={inputRef}
                    />
                    {saveSearchKey && (
                      <datalist id={saveSearchKey}>
                        {getSearch(saveSearchKey).map((value) => {
                          return <option value={value} key={value}></option>;
                        })}
                      </datalist>
                    )}
                    <button type="submit" onClick={handleFilterActive}>
                      <CiSearch
                        className={"fs-25" + (name ? " text-white" : "")}
                      />
                    </button>
                  </div>
                </FormEnter>
                {saveSearchKey && (
                  <div
                    className="saveSearch cursor-pointer"
                    onClick={handleSaveSearch}
                  >
                    {!savedSearch && (
                      <FaFloppyDisk className="text-blue fs-20" />
                    )}
                    {savedSearch && <FaCheck className="text-blue fs-20" />}
                    {!savedSearch && <span>Save Search</span>}
                    {savedSearch && <span>Saved Search</span>}
                  </div>
                )}
                {saveFilterKey && (
                  <div
                    className="saveSearch cursor-pointer"
                    onClick={handleOpen}
                    ref={clickRef}
                  >
                    {!savedSearch && (
                      <FaFloppyDisk className="text-blue fs-20" />
                    )}
                    {savedSearch && <FaCheck className="text-blue fs-20" />}
                    {!savedSearch && <span>Save Filter</span>}
                    {savedSearch && <span>Saved Filter</span>}
                  </div>
                )}
              </>
            )}
            {customChildrenPlacement === "last" && (
              <div
                className="custom | d-flex align-items-center"
                style={{
                  gap: 8,
                  width: filterMobActive ? 0 : "max-content",
                }}
              >
                {customChildren}
              </div>
            )}
          </div>
        </div>
        {showModal && (
          // <div
          //   ref={contRef}
          //   className="dropdown save d-flex gap-2"
          //   style={{
          //     top: y + height + 10,
          //     left: x + width - 280 + 20,
          //     width: 280,
          //   }}
          // >
          //   <input
          //     type="text"
          //     className="form-control bg-transparent"
          //     value={saveText}
          //     onChange={(e) => setSaveText(e.target.value)}
          //     placeholder="Enter Save Filter Name..."
          //   />
          //   <button
          //     className="cbtn btn m-0"
          //     onClick={handleSaveClick}
          //     disabled={isFetchingSaveFilter}
          //   >
          //     {isFetchingSaveFilter ? <FaSpinner /> : "Save"}
          //   </button>
          // </div>
          <SaveFilter
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleClose}
          />
        )}
        {children}
      </div>
    </>
  );
};

export default TableWithHeader;
