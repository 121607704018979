import React, { useCallback } from "react";
import DataTable from "react-data-table-component";
import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa6";
import CBIcon from "../../assets/icons/balancecomp.svg";
import CTIcon from "../../assets/icons/transcomp.svg";
import { tableCustomStyles } from "../../lib/utils";
import LoaderComponent from "../Common/LoaderComponent";

import { BsEye } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { checkForWalletMin } from "../../lib/utility";

const CompanyTable = ({
  companyTableData,
  isFetching,
  editButtonHandler,
  deleteButtonHandler,
  addBalanceHandler,
}) => {
  const navigate = useNavigate();
  const RenderWalletType = ({ walletType }) => (
    <p
      className={
        "badge fw-400 fs-12 text-white rounded-pill mb-0 " +
        (checkForWalletMin(walletType) ? "bg-success" : "bg-warning")
      }
    >
      {checkForWalletMin(walletType) ? "Wallet" : "Credit"}
    </p>
  );
  const columns = [
    {
      name: "Company",
      selector: (row) => row.companyName ?? "-",
      sortable: true,
      width: "110px",
    },
    {
      name: "Company Email",
      selector: (row) => row.companyEmail ?? "-",
      sortable: true,
      width: "130px",
    },
    {
      name: "Registration No",
      selector: (row) => row.registrationNumber ?? "-",
      sortable: true,
      width: "120px",
    },
    { name: "Type", selector: (row) => row.companyType ?? "-" },
    { name: "Country", selector: (row) => row.country ?? "-" },
    { name: "Address", selector: (row) => row.address ?? "-", width: "150px" },
    {
      name: "Telephone",
      selector: (row) => row.telephone ?? "-",
      width: "120px",
    },
    { name: "Mobile", selector: (row) => row.mobile ?? "-", width: "120px" },
    { name: "VAT", selector: (row) => row.vat ?? "-" },
    { name: "NB", selector: (row) => row.nb ?? "-" },
    {
      name: "Wallet Amount",
      selector: (row) => row.walletAmount ?? 0,
    },
    {
      name: "Credit Amount",
      selector: (row) => row.creditsAmount ?? 0,
    },
    {
      name: "Action",
      center: "true",
      sortable: true,
      minWidth: "250px",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <button
            className="bg-light-gray btn fs-12 fw-500 br-8 px-2 py-1 me-2 lh-1"
            onClick={() => {
              navigate("/company/user/" + row.id);
            }}
            data-tooltip="View Members"
            data-tooltip-location="bottom"
          >
            <BsEye size={16} strokeWidth={0.5} />
          </button>
          <button
            className="bg-light-gray btn fs-12 fw-500 br-8 px-2 py-1 me-2 lh-1"
            data-tooltip="Edit"
            data-tooltip-location="bottom"
            onClick={() => {
              editButtonHandler(true, {
                companyName: row.companyName,
                companyEmail: row.companyEmail,
                id: row.id,
                companyType: row.companyType,
                country: row.country,
                telephone: row.telephone,
                mobile: row.mobile,
                address: row.address,
                remark: row.remark,
                registrationNumber: row.registrationNumber,
                vat: row.vat,
                nb: row.nb,
                walletType: row.walletFlag,
              });
            }}
          >
            <FaEdit className="align-bottom" />
          </button>
          <button
            className="bg-light-gray btn fs-12 fw-500 br-8 px-2 py-1 me-2 lh-1"
            data-tooltip="Transactions"
            data-tooltip-location="bottom"
            onClick={() => {
              navigate("/company/transaction-history/" + row.id);
            }}
          >
            <img
              className="align-bottom"
              src={CTIcon}
              loading="lazy"
              style={{ height: 18, margin: "4px 0px" }}
              alt="balance"
            />
          </button>
          <button
            className="bg-light-gray btn fs-12 fw-500 br-8 px-2 py-1 me-2 lh-1"
            data-tooltip="Add Balance"
            data-tooltip-location="bottom"
            onClick={() => {
              addBalanceHandler(true, {
                id: row.id,
              });
            }}
          >
            <img
              className="align-bottom"
              src={CBIcon}
              loading="lazy"
              style={{ height: 18, margin: "4px 0px" }}
              alt="balance"
            />
          </button>
          <button
            className="bg-danger btn fs-12 text-white fw-500 br-8 px-2 py-1 lh-1"
            onClick={() => {
              deleteButtonHandler(true, {
                id: row.id,
                companyName: row.companyName,
              });
            }}
          >
            <FaTrash className="align-bottom" />
          </button>
        </div>
      ),
    },
  ];

  const data = useCallback(companyTableData);

  return (
    <>
      <div className="m-3 table-responsive border br-12 table-action frozen">
        {isFetching ? (
          <LoaderComponent />
        ) : (
          <DataTable
            className="h-100"
            columns={columns}
            data={data}
            highlightOnHover
            fixedHeader
            pagination
            customStyles={tableCustomStyles()}
            noDataComponent={
              <p className="p-4 text-center">No data available</p>
            }
          />
        )}
      </div>
    </>
  );
};

export default CompanyTable;
