import axios from "axios";
import JSONbig from "json-bigint";

const instance = axios.create({
  baseURL: import.meta.env.VITE_REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
  transformResponse: [
    (data) => {
      try {
        return JSONbig.parse(data);
      } catch (error) {
        return data;
      }
    },
  ],
});
const BASE_URL = "http://148.113.8.198:5000/translate";
export const translateText = async (texts, targetLanguage = "hi") => {
  try {
    const body = new FormData();
    body.append("q", texts.join("|"));
    body.append("target", targetLanguage);
    body.append("source", globalThis.prevTranslated ?? "en");

    const response = await axios.post(BASE_URL, body);
    globalThis.prevTranslated = targetLanguage;
    return response.data.translatedText.split("|");
  } catch (error) {
    console.error("Error translating text:", error);
    return [];
  }
};

export default instance;
