import React from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";
import { NoMatch } from "../Error/NoMatch";
import ProgressBar from "./ProgressBar";
import ProtectedRoute from "./ProtectedRoute";
import lazyLoader from "./lazyLoader";
import Company from "../Company/Company";
import CompanyUser from "../Company/CompanyUser";
import CompanyTxnHistory from "../Company/CompanyTxnHistory";
const AddressBook = lazyLoader(() => import("../AddressBook/AddressBook"));
const AddressGroupMembers = lazyLoader(() =>
  import("../AddressBook/GroupMembers/AddressGroupMembers")
);
const Alert = lazyLoader(() => import("../Alert/Alert"));
const Login = lazyLoader(() => import("../Auth/index"));
const BSFM = lazyLoader(() => import("../BSFM/BSFM"));
const Dashboard = lazyLoader(() => import("../Dashboard/Dashboard"));
const DLT = lazyLoader(() => import("../DLT/PrincipalDLT/DLT"));
const TemplateDLT = lazyLoader(() => import("../DLT/TemplateDLT/TemplateDLT"));
const Downloads = lazyLoader(() => import("../Downloads/Downloads"));
const ApprovednReject = lazyLoader(() =>
  import("../Manage/Bulk/ApprovednReject")
);
const Log = lazyLoader(() => import("../Manage/Bulk/Log"));
const SenderList = lazyLoader(() => import("../Manage/Sender/SenderList"));
const SenderLog = lazyLoader(() => import("../Manage/Sender/SenderLog"));
const Message = lazyLoader(() => import("../Message/Message"));
const Network = lazyLoader(() => import("../Network/Network"));
const Profile = lazyLoader(() => import("../Profile/Profile"));
const Aborted = lazyLoader(() => import("../Report/InnerReport/Aborted"));
const Advanced = lazyLoader(() => import("../Report/InnerReport/Advance"));
const Aggregated = lazyLoader(() => import("../Report/InnerReport/Aggregated"));
const BlockedReport = lazyLoader(() =>
  import("../Report/InnerReport/BlockedReport")
);
const Campaign = lazyLoader(() => import("../Report/InnerReport/Campaign"));
const ConsumptionReport = lazyLoader(() =>
  import("../Report/InnerReport/ConsumptionReport")
);
const Content = lazyLoader(() => import("../Report/InnerReport/Content"));
const Coverage = lazyLoader(() => import("../Report/InnerReport/Coverage"));
const DeliverySmsc = lazyLoader(() =>
  import("../Report/InnerReport/DeliverySmsc")
);
const DeliverySummary = lazyLoader(() =>
  import("../Report/InnerReport/DeliverySummary")
);
const Latency = lazyLoader(() => import("../Report/InnerReport/Latency"));
const LookUpReport = lazyLoader(() =>
  import("../Report/InnerReport/LookUpReport")
);
const PerformanceRoute = lazyLoader(() =>
  import("../Report/InnerReport/PerformanceRoute")
);
const PerformanceUser = lazyLoader(() =>
  import("../Report/InnerReport/PerformanceUser")
);
const Profit = lazyLoader(() => import("../Report/InnerReport/Profit"));
const ScheduleReport = lazyLoader(() =>
  import("../Report/InnerReport/ScheduleReport")
);
const SubmissionReport = lazyLoader(() =>
  import("../Report/InnerReport/Submission")
);
const Summary = lazyLoader(() => import("../Report/InnerReport/Summary"));
const AddRouting = lazyLoader(() => import("../Routing/AddRouting"));
const CopyRouting = lazyLoader(() => import("../Routing/CopyRouting"));
const EditRouting = lazyLoader(() => import("../Routing/EditRouting"));
const PackageRouting = lazyLoader(() => import("../Routing/PackageRouting"));
const AddSenderRouting = lazyLoader(() =>
  import("../Routing/Sender/AddSenderRouting")
);
const EditSenderRouting = lazyLoader(() =>
  import("../Routing/Sender/EditSenderRouting")
);
const Sales = lazyLoader(() => import("../Sales/Sales"));
const SMSCBlocking = lazyLoader(() => import("../SMSC/Blocking/SMSCBlocking"));
const SMSCGroup = lazyLoader(() => import("../SMSC/Group/SMSCGroup"));
const SMSCGroupMember = lazyLoader(() =>
  import("../SMSC/Group/SMSCGroupMember")
);
const SMSCHLR = lazyLoader(() => import("../SMSC/HLR/SMSCHLR"));
const SMSCHLRFlag = lazyLoader(() => import("../SMSC/HLR/SMSCHLRFlag"));
const SMSCHLRStatus = lazyLoader(() => import("../SMSC/HLR/SMSCHLRStatus"));
const SMSCLimits = lazyLoader(() => import("../SMSC/Limits/SMSCLimits"));
const SMSCLooping = lazyLoader(() => import("../SMSC/Looping/SMSCLooping"));
const SMSCSchedule = lazyLoader(() => import("../SMSC/Schedule/SMSCSchedule"));
const SMSC = lazyLoader(() => import("../SMSC/SMSC"));
const SMSCFlag = lazyLoader(() => import("../SMSC/SMSCFlag"));
const SMSCStatus = lazyLoader(() => import("../SMSC/SMSCStatus"));
const SMSCSpecialSetting = lazyLoader(() =>
  import("../SMSC/Special Setting/SMSCSpecialSetting")
);
const Subscription = lazyLoader(() => import("../Subscription/Subscription"));
const Templates = lazyLoader(() => import("../Templates/Templates"));
const TwoWay = lazyLoader(() => import("../TwoWay/TwoWay"));
const TwoWayReport = lazyLoader(() => import("../TwoWay/TwoWayReport"));
const UpdatePassword = lazyLoader(() =>
  import("../UpdatePassword/UpdatePassword")
);
const UserLimit = lazyLoader(() => import("../User/Limits/UserLimit"));
const UserModule = lazyLoader(() => import("../User/UserModule"));
const Converter = lazyLoader(() => import("../Utility/Converter"));
const BulkEdit = lazyLoader(() => import("../Utility/Edit/Bulk/BulkEdit"));
const ScheduleEdit = lazyLoader(() =>
  import("../Utility/Edit/Schedule/ScheduleEdit")
);
const WalletPage = lazyLoader(() => import("../Wallet/WalletPage"));

const router = createBrowserRouter([
  {
    element: (
      <>
        <ProgressBar />
        <Outlet />
      </>
    ),
    errorElement: <NoMatch />,
    children: [
      {
        element: <ProtectedRoute role={"public"} />,
        errorElement: <NoMatch />,
        children: [
          {
            path: "/update-password",
            element: <UpdatePassword />,
          },
        ],
      },
      {
        element: <ProtectedRoute role={"guest"} />,
        errorElement: <NoMatch />,
        children: [
          {
            path: "/",
            element: <Login />,
            errorElement: <NoMatch />,
          },
          {
            path: "/login",
            element: <Login />,
            errorElement: <NoMatch />,
          },
        ],
      },
      {
        element: (
          <ProtectedRoute
            role={"user,admin,superadmin,system,seller,manager"}
          />
        ),
        errorElement: <NoMatch />,
        children: [
          {
            path: "/dashboard",
            element: <Dashboard />,
          },
          {
            path: "/update-password",
            element: <UpdatePassword />,
          },
          {
            path: "/profile",
            element: <Profile />,
          },
        ],
      },
      {
        element: <ProtectedRoute role={"user,admin,superadmin,system"} />,
        errorElement: <NoMatch />,
        children: [
          {
            path: "/message",
            element: <Message />,
          },
          {
            path: "/utilities/",
            children: [
              {
                path: "two-way",
                element: <TwoWay />,
              },
              {
                path: "two-way-report",
                element: <TwoWayReport />,
              },
              {
                path: "converter",
                element: <Converter />,
              },
              {
                path: "subscription",
                element: <Subscription />,
              },
              {
                path: "edit/bulk",
                element: <BulkEdit />,
              },
              {
                path: "edit/schedule",
                element: <ScheduleEdit />,
              },
            ],
          },
          {
            path: "/approvals/",
            children: [
              {
                path: "sender/",
                children: [
                  { path: "approved-reject", element: <SenderList /> },
                  { path: "log", element: <SenderLog /> },
                ],
              },
            ],
          },
          {
            path: "/reports/",
            children: [
              {
                path: "campaign/",
                children: [
                  {
                    path: "aborted",
                    element: <Aborted />,
                  },
                  {
                    path: "schedule",
                    element: <ScheduleReport />,
                  },
                  {
                    path: "campaign",
                    element: <Campaign />,
                  },
                ],
              },
              {
                path: "summary/",
                children: [
                  {
                    path: "delivery",
                    element: <DeliverySummary />,
                  },
                  {
                    path: "batch",
                    element: <Summary />,
                  },
                ],
              },
              {
                path: "detailed/",
                children: [
                  {
                    path: "advanced",
                    element: <Advanced />,
                  },
                  {
                    path: "content",
                    element: <Content />,
                  },
                ],
              },
              {
                path: "latency",
                element: <Latency />,
              },
              {
                path: "lookup",
                element: <LookUpReport />,
              },
              {
                path: "consumption",
                element: <ConsumptionReport />,
              },
            ],
          },
          {
            path: "/address-book",
            children: [
              {
                path: "",
                element: <AddressBook />,
              },
              {
                path: "members/:id",
                element: <AddressGroupMembers />,
              },
            ],
          },
          {
            path: "/template",
            element: <Templates />,
          },
          {
            path: "/wallet",
            element: <WalletPage />,
          },
          {
            path: "/downloads",
            element: <Downloads />,
          },
        ],
      },
      {
        element: <ProtectedRoute role={"admin,superadmin,system"} />,
        errorElement: <NoMatch />,
        children: [
          {
            path: "/",
            children: [
              { path: "company", element: <Company /> },
              { path: "company/user/:companyId", element: <CompanyUser /> },
              {
                path: "company/transaction-history",
                element: <CompanyTxnHistory />,
              },
              {
                path: "company/transaction-history/:companyId",
                element: <CompanyTxnHistory />,
              },
            ],
          },
          {
            path: "/reports/",
            children: [
              {
                path: "aggregated",
                element: <Aggregated />,
              },
              {
                path: "profit",
                element: <Profit />,
              },
            ],
          },
          {
            path: "/approvals/bulk",
            children: [
              { path: "approved-reject", element: <ApprovednReject /> },
              { path: "log", element: <Log /> },
            ],
          },
          {
            path: "/firewall",
            element: <BSFM />,
          },
          {
            path: "/sales",
            element: <Sales />,
          },
          {
            path: "/user",
            element: <UserModule />,
          },
          {
            path: "/user/list",
            element: <UserModule />,
          },
          {
            path: "/routing",
            children: [
              {
                path: "edit",
                element: <EditRouting />,
              },
              {
                path: "copy",
                element: <CopyRouting />,
              },
              // {
              //   path: "package",
              //   element: <PackageRouting />,
              // },
              {
                path: "sender",
                children: [
                  {
                    path: "add",
                    element: <AddSenderRouting />,
                  },
                  {
                    path: "edit",
                    element: <EditSenderRouting />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <ProtectedRoute role={"superadmin,system"} />,
        errorElement: <NoMatch />,
        children: [
          {
            path: "/user/limit",
            element: <UserLimit />,
          },
          {
            path: "/smsc",
            children: [
              {
                path: "list",
                element: <SMSC />,
              },
              {
                path: "flag",
                element: <SMSCFlag />,
              },
              {
                path: "status",
                element: <SMSCStatus />,
              },
              {
                path: "limits",
                element: <SMSCLimits />,
              },
              {
                path: "alert",
                element: <Alert />,
              },
              {
                path: "looping",
                element: <SMSCLooping />,
              },
              {
                path: "hlr",
                element: <SMSCHLR />,
              },
              {
                path: "hlr-flag",
                element: <SMSCHLRFlag />,
              },
              {
                path: "hlr-status",
                element: <SMSCHLRStatus />,
              },
              {
                path: "blocking",
                element: <SMSCBlocking />,
              },
              {
                path: "schedule",
                element: <SMSCSchedule />,
              },
              {
                path: "special-settings",
                element: <SMSCSpecialSetting />,
              },
              {
                path: "group-data/:groupId",
                element: <SMSCGroupMember />,
              },
              {
                path: "group-data",
                element: <SMSCGroup />,
              },
            ],
          },
          {
            path: "/network",
            element: <Network />,
          },
          {
            path: "/dlt",
            children: [
              {
                path: "principleEntityID",
                element: <DLT />,
              },
              {
                path: "templateDlt",
                element: <TemplateDLT />,
              },
            ],
          },
          {
            path: "/routing",
            children: [
              {
                path: "add",
                element: <AddRouting />,
              },
            ],
          },
          {
            path: "/reports",
            children: [
              {
                path: "performance/user",
                element: <PerformanceUser />,
              },
              {
                path: "performance/route",
                element: <PerformanceRoute />,
              },
              {
                path: "coverage-report",
                element: <Coverage />,
              },
              {
                path: "blocked",
                element: <BlockedReport />,
              },
            ],
          },
        ],
      },
      {
        element: <ProtectedRoute role={"admin"} />,
        errorElement: <NoMatch />,
        children: [
          {
            path: "/reports/detailed/smsc",
            element: <DeliverySmsc />,
          },
        ],
      },
      {
        element: <ProtectedRoute role={"seller,manager"} />,
        errorElement: <NoMatch />,
        children: [
          {
            path: "/reports/delivery-advance",
            element: <Advanced />,
          },
          {
            path: "/reports/batch-summary",
            element: <Summary />,
          },
          {
            path: "/reports/user-status",
            element: <UserModule isSellerId />,
          },
          {
            path: "/reports/submission",
            element: <SubmissionReport isSeller={true} />,
          },
          {
            path: "/reports/transaction-history",
            element: <WalletPage isSeller={true} />,
          },
          {
            path: "/reports/performance-user",
            element: <PerformanceUser />,
          },
          {
            path: "/reports/coverage",
            element: <Coverage />,
          },
        ],
      },
      {
        element: <ProtectedRoute role={"manager"} />,
        errorElement: <NoMatch />,
        children: [
          {
            path: "/sales-list",
            element: <Sales />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NoMatch />,
  },
]);
export default router;
