import { Document, Packer, TextRun, Paragraph, File as DocxFile } from "docx";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import NProgress from "nprogress";
import { sizesArray } from "./utils";
import { ChartColors } from "../components/Report/Charts/ChartColors";
import { translateText } from "../redux/axios";

NProgress.configure({ showSpinner: false, trickle: true });

export function handleModalCloseOnEscape(e, close: () => void): void {
  if (e.key === "Escape" && close) close();
}
export function removeItem(key: string) {
  localStorage.removeItem(key);
}
export function storeItem(key: string, value: any) {
  if (value === null || value === undefined || typeof key !== "string")
    console.error("key must be string and value must be provided");
  localStorage.setItem(key, JSON.stringify(value));
}
export function getItem(key: string, jsonParse: boolean) {
  if (jsonParse) return JSON.parse(localStorage.getItem(key) ?? "false");
  return localStorage.getItem(key);
}
export function getLocalUser(
  id: "map" | "object" | "systemId",
  length: number
): string[] | Record<number, string>[] {
  if (id === "map") return JSON.parse(getItem("userIdsMap", true));
  if (id === "object")
    return Object.entries(JSON.parse(getItem("userIdsMap", true))).map(
      ([id, systemId]) => ({ id, systemId })
    );
  return (
    Object[!id || id !== "systemId" ? "keys" : "values"](
      JSON.parse(getItem("userIdsMap", true))
    ).slice(0, length) ?? ""
  );
}
export const showToast = (
  message: String,
  type: "error" | "warning" | "success",
  duration = 3000
) => {
  toast(message, {
    type,
    position: "top-right",
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: `custom-toast ${type}`, // Add your custom CSS classes here
  });
};
export const formatTime = (timeInSeconds: number) => {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  if (timeInSeconds > 3600) {
    while (timeInSeconds >= 3600) {
      hours += 1;
      timeInSeconds -= 3600;
    }
  }

  if (timeInSeconds > 60) {
    while (timeInSeconds >= 60) {
      minutes += 1;
      timeInSeconds -= 60;
    }
  }

  seconds = timeInSeconds;
  return (
    (hours > 0 ? `${hours}h:` : "") +
    (hours > 0 || minutes > 0 ? `${minutes}m:` : "") +
    (minutes > 0 || seconds > 0 ? `${seconds}s` : "")
  );
};
export const formatDate = (dateStr: string) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (!dateStr) return "";
  if (dateStr.split("-").length < 3) {
    showToast("Date format is incorrect", "warning");

    return;
  }
  const [year, monthIndex, day] = dateStr.split("-");
  const month = months[parseInt(monthIndex, 10) - 1];

  return `${day} ${month}, ${year}`;
};
export const convertArrayOfObjectsToCSV = (array: Record<string, any>[]) => {
  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  // Create CSV header
  let csv = keys.join(columnDelimiter) + lineDelimiter;

  // Create CSV rows
  array.forEach((item) => {
    csv +=
      keys.map((key) => '"' + item[key] + '"').join(columnDelimiter) +
      lineDelimiter;
  });

  return csv;
};
export const downloadCSV = (
  array: Record<string, any>[],
  filename: string = "Report.csv"
) => {
  const csv = convertArrayOfObjectsToCSV(array);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  // Create download link
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.style.display = "none";

  // Append link to DOM and trigger download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export function checkForWalletMin(flag: "YES" | "NO") {
  return flag && (flag.toLowerCase() === "yes" || flag.toLowerCase() === "min");
}
export function saveSearch(module: string, searchVal: string) {
  if (searchVal === "") return;

  let localSearch = getItem("savedSearch", true) || {};

  if (localSearch[module] && Array.isArray(localSearch[module])) {
    if (!localSearch[module].includes(searchVal))
      localSearch[module] = [...localSearch[module], searchVal];
  } else localSearch[module] = [searchVal];
  storeItem("savedSearch", localSearch);
}
export function getSearch(module: string) {
  let localSearch = getItem("savedSearch", true);
  if (localSearch && typeof localSearch === "object")
    return localSearch[module] ?? [];
  else return [];
}
export const isArray = (val: any) => {
  return val && typeof val === "object" && Array.isArray(val);
};
export const isValidString = (val: any) => {
  return val && val.trim() !== "";
};
export const isObject = (val: any) => {
  return val && typeof val === "object";
};
export const hasProperty = (obj: Record<string, any>, key: string) => {
  if (!isObject(obj) || !obj || typeof obj.hasOwnProperty !== "function")
    return false;
  return obj.hasOwnProperty(key);
};
export const kindOf = (obj: Record<string, any>, value: string) => {
  return obj && typeof obj === value.toLowerCase();
};
export const addTimeInDates = (
  filterData: Record<string, any>,
  offset: number
) => {
  return {
    ...filterData,
    ...(filterData.startDate
      ? {
        startDate: `${getDateInTimeZone(
          offset,
          filterData.startDate?.split(" ")[0]
        )} 00:00:00`,
      }
      : {}),
    ...(filterData.endDate
      ? {
        endDate: `${getDateInTimeZone(
          offset,
          filterData.endDate?.split(" ")[0]
        )} 23:59:59`,
      }
      : {}),
  };
};
export const skipNullValue = (
  obj: Record<string, any>,
  toInclude: any[] = []
) => {
  let body: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    if (!toInclude.includes(obj[key])) {
      if (!key.includes("search"))
        if (obj[key] && obj[key] !== "") body[key] = obj[key];
    } else body[key] = obj[key];
  });

  return body;
};
export const dataToDocx = (value: string) => {
  if (value === "") {
    return;
  }
  const doc = new Document({
    sections: [
      {
        properties: {},
        children: [
          new Paragraph({
            children: [new TextRun(value)],
          }),
        ],
      },
    ],
  });
  saveDocumentToFile(doc, `converter.docx`);
};
function saveDocumentToFile(doc: DocxFile, fileName: string) {
  const mimeType =
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  Packer.toBlob(doc).then((blob) => {
    const docblob = blob.slice(0, blob.size, mimeType);
    saveAs(docblob, fileName);
  });
}
export const checkUserRole = (user: any, role: string) => {
  if (user && user.roles && isArray(user.roles)) {
    return user.roles.includes(role);
  } else if (user && user.role && typeof user.role === "string") {
    return user.role.includes(role);
  } else return false;
};
export const isUser = (user: any) => checkUserRole(user, "user");
export const isAdmin = (user: any) => checkUserRole(user, "admin");
export const isSystem = (user: any) => checkUserRole(user, "system");
export const isSeller = (user: any) => checkUserRole(user, "seller");
export const isManager = (user: any) => checkUserRole(user, "manager");
export const isSuperAdmin = (user: any) => checkUserRole(user, "superadmin");
export const isGuest = (user: any) => !(user && user.roles && user.roles[0]);
export const hasRoles = (rolesString: string, user: any) => {
  const roles = rolesString.split(",");
  return roles.some((role) => {
    return checkUserRole(user, role);
  });
};
export const hasRole = (user: any) => {
  return (
    user &&
    ((user.roles && user.roles[0]) ||
      (user.role && typeof user.role === "string"))
  );
};
export const filterDataByField = (
  data: any[],
  fields: string | string[],
  searchVal: string | number | boolean | object,
  subKey?: string,
  valueMap?: Map<string, string>
) => {
  if (searchVal === "" || !searchVal) return data;
  if (typeof searchVal !== "string")
    searchVal = searchVal.toString ? searchVal.toString() : String(searchVal);

  fields = typeof fields === "string" ? fields.split(",") : [];
  return data.filter((row) => {
    let bool = false;
    fields.forEach((field) => {
      let value =
        valueMap && typeof valueMap[field] === "function"
          ? valueMap[field](row[field])
          : subKey
            ? row[subKey] && row[subKey][field]
            : row[field];
      if (
        value &&
        value.toString().toLowerCase().includes(searchVal.trim().toLowerCase())
      ) {
        bool = true;
      }
    });
    return bool;
  });
};
export const convertToInt = (value: any) => {
  if (typeof value === "number") return value;
  if (typeof value === "string") return Number.isInteger(value) ? +value : NaN;
  if (typeof value === "boolean") return Number(value);
  if (typeof value === "object" && Array.isArray(value))
    return value.map((v) => convertToInt(v));
  else NaN;
};
export const someTrue = (obj: any, values: string | undefined | null) => {
  if (!obj || !values || values === "") return true;
  let bool = false;
  for (let i = 0; i < values.split(",").length; i++) {
    let v = values.split(",")[i];
    if (obj[v]) {
      bool = true;
      break;
    }
  }
  return bool;
};
export const numberFromFile = async (
  items: File[],
  options: { columnIndex?: number } = {}
) => {
  let { columnIndex = 0 } = options;
  let totalNum = 0;

  for (const item of items) {
    const fileName = item.name;
    if (
      !fileName ||
      (!fileName.endsWith(".txt") &&
        !fileName.endsWith(".csv") &&
        !fileName.endsWith(".xls") &&
        !fileName.endsWith(".xlsx"))
    ) {
      continue;
    }
    try {
      const content = await item.arrayBuffer();

      if (fileName.endsWith(".txt") || fileName.endsWith(".csv")) {
        totalNum += processTextFile(
          content,
          fileName.toLowerCase().includes("custom")
            ? fileName.endsWith(".txt")
              ? ";"
              : ","
            : false
        );
      } else {
        totalNum += await processExcelFile(content, fileName, columnIndex);
      }
    } catch (e) {
      console.error("Error processing file: " + fileName, e);
      throw new Error("Error processing files " + e.getMessage());
    }
  }

  return totalNum;
};
function processTextFile(content: any, custom: boolean | string) {
  const decoder = new TextDecoder("utf-8");
  const text = decoder.decode(content);
  const lines = text.split("\n");
  let numCount = 0;

  for (const line of lines) {
    const number = validateNumber(line, custom);
    if (number !== null) {
      numCount++;
    }
  }
  return numCount;
}
async function processExcelFile(content: any, fileName: string, index = 0) {
  const workbook = fileName.endsWith(".xlsx")
    ? XLSX.read(new Uint8Array(content), { type: "array" })
    : XLSX.read(new Uint8Array(content), { type: "binary" });

  const sheet: { [sheet: string]: XLSX.WorkSheet } =
    workbook.Sheets[workbook.SheetNames[0]];
  const data: any[] = XLSX.utils.sheet_to_json(sheet, { header: 1 });

  let numCount = 0;

  for (const row of data) {
    if (row[index]) {
      const cellValue = row[index].toString();
      const number = validateNumber(cellValue);
      if (number !== null) {
        numCount++;
      }
    }
  }
  return numCount;
}
function validateNumber(number: string | number, isCustom?: boolean | string) {
  try {
    if (typeof number === "string") {
      if (isCustom && typeof isCustom === "string") {
        number = number.split(isCustom)[0];
      }
      number = number.replace(/\s+/g, "");
      if (number.startsWith("+")) {
        number = number.substring(number.lastIndexOf("+") + 1);
      }
    }
    if (number === "") return null;

    number = String(Number(number));
    if (number !== "NaN") return number;
    else return null;
  } catch (ex) {
    console.debug("Invalid Destination Found => " + number);
    return null;
  }
}
export async function getParamsFromCustomFile(items: File[]) {
  let item = items[0];
  let fileName = item.name;
  const content = await item.arrayBuffer();
  const decoder = new TextDecoder("utf-8");
  const text = decoder.decode(content);
  const lines = text.split("\n");

  if (fileName.endsWith(".txt")) {
    return lines[0].split(";").length - 1;
  } else if (fileName.endsWith(".csv")) {
    return lines[0].split(",").length - 1;
  } else if (fileName.endsWith(".txt")) {
    const workbook = fileName.endsWith(".xlsx")
      ? XLSX.read(new Uint8Array(content), { type: "array" })
      : XLSX.read(new Uint8Array(content), { type: "binary" });

    const sheet: { [sheet: string]: XLSX.WorkSheet } =
      workbook.Sheets[workbook.SheetNames[0]];
    const data: any[] = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    return data[0].length - 1;
  }
}
export function downloadFile(path: string, filename: string) {
  const link = document.createElement("a");
  link.href = path;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export const getBrowserGMTOffset = (inHours: boolean) => {
  const currentDate = new Date();
  const timezoneOffsetInMinutes = currentDate.getTimezoneOffset();

  const offsetHours = Math.floor(Math.abs(timezoneOffsetInMinutes) / 60);
  const offsetMinutes = Math.abs(timezoneOffsetInMinutes) % 60;
  const offsetSign = timezoneOffsetInMinutes <= 0 ? "+" : "-";

  if (inHours)
    return (offsetHours + offsetMinutes / 60) * (offsetSign === "+" ? 1 : -1);
  const formattedOffset = `GMT${offsetSign}${String(offsetHours).padStart(
    2,
    "0"
  )}:${String(offsetMinutes).padStart(2, "0")}#1`;

  return formattedOffset;
};
export function getDateInTimeZone(offset: number, dateString: string) {
  let [dateYear, dateMonth, dateDay] = dateString.split("-").map((p) => +p);
  let date = new Date();
  date.setUTCFullYear(dateYear);
  date.setUTCMonth(dateMonth - 1);
  date.setUTCDate(dateDay);

  let dateInTimeZone = new Date(date.getTime() + offset * 60000);

  let year = dateInTimeZone.getUTCFullYear();
  let month = String(dateInTimeZone.getUTCMonth() + 1).padStart(2, "0");
  let day = String(dateInTimeZone.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
export function convertGmtToOffest(gmt: string) {
  let [hour = "0", minutes = "0"] = gmt
    .replace("GMT", "")
    .split("#")[0]
    .split(":");
  return +hour * 60 + +minutes;
}
export function capitalize(val: any) {
  if (!val || typeof val !== "string") return "";
  return val.substring(0, 1).toUpperCase() + val.substring(1);
}
export function convertBytesToString(val: string | number): string {
  let level = 0;
  if (typeof val === "string")
    if (!Number.isNaN(val)) val = +val;
    else return `0${sizesArray[level]}`;

  while (val >= 1024 && level < sizesArray.length - 1) {
    val /= 1024;
    level++;
  }

  return `${(val ?? 0).toFixed(2)}${sizesArray[level]}`;
}
export function startProgress(stop: boolean = true, timeout: number = 400) {
  NProgress.start();

  if (stop) {
    setTimeout(() => {
      NProgress.done();
    }, timeout);
  }
}
export function stopProgress() {
  NProgress.done();
}
export function openModalQuery(
  modalName: string,
  navigate: (pathName: string) => void
) {
  if (!modalName || modalName === "") return;
  navigate(`${window.location.pathname}?${modalName}=true`);
}
export function closeModalQuery() {
  window.history.pushState({}, "", window.location.pathname);
}
export function getStylesForStatus(status: string | undefined) {
  if (!status || status === "")
    return {
      color: "white",
      backgroung: ChartColors.advertising,
    };

  return {
    color: "white",
    background: ChartColors.advertising,
    ...(status === "DELIVRD"
      ? { background: ChartColors.delivered, color: "#2a2a2a" }
      : {}),
    ...(status === "REJECT" || status === "REJECTED"
      ? { background: ChartColors.rejected, color: "#2a2a2a" }
      : {}),
    ...(status === "Success"
      ? { background: ChartColors.delivered, color: "#2a2a2a" }
      : {}),
    ...(status === "UNDELIV"
      ? { background: ChartColors.undelivered, color: "#2a2a2a" }
      : {}),
    ...(status === "EXPIRED"
      ? { background: ChartColors.expired, color: "#2a2a2a" }
      : {}),
    ...(status === "BLOCKED"
      ? { background: ChartColors.blocked, color: "#2a2a2a" }
      : {}),
    ...(status === "ATES"
      ? { background: ChartColors.ates, color: "#2a2a2a" }
      : {}),
    ...(status === "ACCEPTD"
      ? { background: ChartColors.accepted, color: "#2a2a2a" }
      : {}),
    ...(status === "PENDING"
      ? { background: ChartColors.pending, color: "#2a2a2a" }
      : {}),
    ...(status === "QUEUED"
      ? { background: ChartColors.queued, color: "#2a2a2a" }
      : {}),
    ...(status === "UNPROCD"
      ? { background: ChartColors.unprocessed, color: "#2a2a2a" }
      : {}),
  };
}
export const filterBlankFields = (
  obj: Record<string, any>
): Record<string, any> => {
  let filteredObj = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] && obj[key] !== "") {
      filteredObj[key] = obj[key];
    }
  });

  return filteredObj;
};
export const extractTextNodes = (): HTMLElement[] => {
  const textNodes: HTMLElement[] = [];
  const elements = document.querySelectorAll(
    "body *"
  ) as NodeListOf<HTMLElement>;

  // Filter out text nodes that are not relevant
  elements.forEach((el: HTMLElement) => {
    if (
      el.children.length === 0 &&
      el.innerText &&
      el.innerText.trim() !== ""
    ) {
      textNodes.push(el);
    }
  });

  return textNodes;
};
export const extractTextNodesWithAttributes = () => {
  const textNodes: { element: HTMLElement; type: string; content: string }[] =
    [];
  const elements = document.querySelectorAll(
    "body *"
  ) as NodeListOf<HTMLElement>;

  elements.forEach((el) => {
    // Collect visible text
    if (
      el.children.length === 0 &&
      el.innerText &&
      el.innerText.trim() !== ""
    ) {
      textNodes.push({
        element: el,
        type: "text",
        content: el.innerText.trim(),
      });
    }

    // Collect placeholders for input elements
    if (el.tagName === "INPUT" || el.tagName === "TEXTAREA") {
      const placeholder = el.getAttribute("placeholder");
      if (placeholder && placeholder.trim() !== "") {
        textNodes.push({
          element: el,
          type: "placeholder",
          content: placeholder.trim(),
        });
      }
    }

    // Collect `alt` text for images
    if (el.tagName === "IMG") {
      const altText = el.getAttribute("alt");
      if (altText && altText.trim() !== "") {
        textNodes.push({
          element: el,
          type: "alt",
          content: altText.trim(),
        });
      }
    }
  });

  return textNodes;
};

export const translatePageContent = async (selectedLanguage = "hi") => {
  // Extract all text nodes
  const textNodes = extractTextNodesWithAttributes();

  // Translate all text nodes in one request
  const translations = await translateText(
    textNodes.map((el) => el.content),
    selectedLanguage
  );

  translations.forEach((translation: string, index: number) => {
    const { element, type } = textNodes[index];

    if (type === "text") {
      element.innerText = translation;
    } else if (type === "placeholder") {
      element.setAttribute("placeholder", translation);
    } else if (type === "alt") {
      element.setAttribute("alt", translation);
    }
  });
};

export const validateFile = (file: File, allowedExtensions: string[]): boolean => {
  const fileExtension = file.name.split('.').pop()?.toLowerCase();
  return fileExtension ? allowedExtensions.includes(fileExtension) : false;
};





