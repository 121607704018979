import { useNavigate } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";

const RedirectToDashboard = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const redirect = queryParams.get("redirect");

  useFetch(() => {
    navigate(redirect ?? "/dashboard");
  });
  return <></>;
};
export default RedirectToDashboard;
