import NotificationDarkIcon from "../../../assets/img/notificationDark.svg";
import CloseButton from "../../../components/Common/CloseButton";
import Modal from "../../../components/Common/Modal";
import "./notification.scss";
import NotificationCard from "./NotificationCard";

const NotificationModal = ({
  show,
  close,
  isLoading,
  notificationData,
  deleteNotificationOnce,
  handleNotificationClick,
}) => {
  return (
    <Modal show={show} close={close} justify="right" centerOnMobile>
      <div className="bg-white h-100">
        <div className="modal-header" style={{ borderRadius: 0 }}>
          <h6 className="modal-type d-flex align-items-center fs-22 gap-2">
            <img src={NotificationDarkIcon} loading="lazy" />
            Notifications
          </h6>
          <CloseButton onClick={close} theme="dark" />
        </div>
        <div
          className="modal-body p-3"
          style={{
            overflow: "auto",
            height: "calc(100% - 80px)",
          }}
        >
          {Array.isArray(notificationData) && (
            <>
              {notificationData.map((noti, index) => (
                <NotificationCard
                  key={noti.id}
                  notification={noti}
                  delete={deleteNotificationOnce}
                  handleNotificationClick={(id, read) =>
                    handleNotificationClick(id, index, read)
                  }
                />
              ))}
            </>
          )}

          {!isLoading && notificationData.length === 0 && (
            <p className="py-4 w-100 text-center fs-4 ">
              No Notification Found
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default NotificationModal;
